<div class="py-4 pb-5">
  <span class="align-center" *ngIf="!cart.items.length" style="font-style: italic; color: gray;">No Items In Cart</span>
  <div class="mb-3" *ngFor="let item of cart.items">
    <div class="d-flex" style="width: 100%;">
      <div *ngIf="image"  class="d-flex" style="width: 20%; padding-right: 10px;">
        <img style="max-height: 90px; max-width: 100%; min-height: 90px;" src="{{item.ItemImageURL ? item.ItemImageURL : 'https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled-1150x647.png'}}" alt="..." />
      </div>
      <div style="width: 100%;">
        <div>
          <span>{{item.ProductName}}</span>
          <span style="float: right;">${{item.SalePrice * item.quantity | number}}</span>
        </div>
        <div class="d-flex">
          <div class="d-flex mt-2">
            <span style="color: gray;" class="d-flex">Quantity:
              <div *ngIf="item.editing" class="pl-3">
                <input [(ngModel)]="item.quantity" type="text" style="max-width: 40px; max-height: 25px; font-size: 15px; text-align: center;">
              </div>
              <div *ngIf="!item.editing" class="ml-2">
                {{item.quantity}}
              </div>
            </span>
          </div>
          <div class="d-flex ml-5">
            <div class="d-flex" *ngIf="!item.editing">
              <span class="btn" style="color: rgb(36, 36, 228); margin-left: 5%;" (click)="edit(item)">Edit</span>
              <span class="btn" style="color: rgb(145, 0, 0); margin-left: 5%;" (click)="removeCartItem(item)">Remove</span>
            </div>
            <div class="d-flex" *ngIf="item.editing">
              <span class="btn" style="color: rgb(36, 36, 228); margin-left: 5%;" (click)="updateItemQuantity(item)">Save</span>
              <span class="btn" style="color: rgb(145, 0, 0); margin-left: 5%;" (click)="cancel(item)">Cancel</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</div>
