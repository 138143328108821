<div *ngIf="!loaded"  class="d-flex justify-content-center mt-5" style="padding-top: 200px;">
  <div class="spinner-border text-secondary" style="width: 6rem; height: 6rem;" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div *ngIf="loaded" style="padding: 2%">
  <div style="margin-top: 45px; text-align: center; color: grey; font-weight: 400; font-size: x-large;">
    Checkout
  </div>
  <hr>
  <div class="container">
    <div class="row">
        <div *ngIf="stage === 'shipping' || stage === 'payment'" class="col-md-4 order-md-2 mb-4">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
                <span class="text-muted">Your cart</span>
                <span class="badge badge-secondary badge-pill">{{cart.items.length}}</span>
            </h4>
            <ul class="list-group mb-3">
                <li *ngFor="let item of cart.items" class="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 class="my-0">{{item.ProductName}}</h6>
                        <small class="text-muted">Quantity: {{item.quantity}}</small>
                    </div>
                    <span class="text-muted">${{item.SalePrice | number}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <span class="text-muted">Cart Total</span>
                  <span class="text-muted">${{cart.total | number : '1.2-2'}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                    <span class="text-muted">Sales Tax <span *ngIf="salesTaxPercent">&nbsp;({{salesTaxPercent}}%)</span></span>
                    <span *ngIf="salesTax === undefined" class="text-muted"> ~ </span>
                    <span *ngIf="salesTax !== undefined" class="text-muted">${{salesTax | number : '1.2-2'}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <span class="text-muted">Service Fee &nbsp;(3.5%)</span>
                  <span *ngIf="salesTax === undefined" class="text-muted"> ~ </span>
                  <span *ngIf="salesTax !== undefined" class="text-muted">${{(cart.total + salesTax) * (serviceFee/100) | number : '1.2-2'}}</span>
              </li>
                <li class="list-group-item d-flex justify-content-between">
                    <span>Total</span>
                    <span *ngIf="salesTax === undefined" class="text-muted"> ~ </span>
                    <span *ngIf="salesTax !== undefined" class="text-muted">${{orderTotal | number : '1.2-2'}}</span>
                </li>

            </ul>

            <!-- <div class="card p-2">
              <div class="input-group">
                  <input type="text" class="form-control" placeholder="Promo code">
                  <div class="input-group-append">
                      <button type="submit" class="btn btn-secondary">Redeem</button>
                  </div>
              </div>
            </div> -->
        </div>
        <div *ngIf="stage === 'shipping'" class="col-md-8 order-md-1">
            <h4 class="mb-3">Shipping Information</h4>
            <form name="checkoutForm" #checkoutForm id="checkoutForm">
                <div class="mb-3">
                  <label for="companyName">Company Name</label>
                  <input name="companyName" (focus)="resetFormField('companyName')" type="text" class="form-control" id="companyName">
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="firstName">First name<span style="color: red;">&nbsp;*</span></label>
                        <input name="firstName" (focus)="resetFormField('firstName')" type="text" [ngClass]="{'form-control': validFirstName, 'form-control-error': !validFirstName}" id="firstName" placeholder="" value="" required>
                        <div *ngIf="!validFirstName" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Valid first name is required. </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="lastName">Last name<span style="color: red;">&nbsp;*</span></label>
                        <input name="lastName" (focus)="resetFormField('lastName')" type="text" [ngClass]="{'form-control': validLastName, 'form-control-error': !validLastName}" id="lastName" placeholder="" value="" required>
                        <div *ngIf="!validLastName" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Valid last name is required. </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                      <label for="email">Email<span style="color: red;">&nbsp;*</span></label>
                      <input name="email" (focus)="resetFormField('email')" type="email" [ngClass]="{'form-control': validEmail, 'form-control-error': !validEmail}" id="email" required>
                      <div *ngIf="!validEmail" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Please enter a valid email address. </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="email">Phone<span style="color: red;">&nbsp;*</span></label>
                    <input name="phone" (focus)="resetFormField('phone')" type="phone" [ngClass]="{'form-control': validPhone, 'form-control-error': !validPhone}" id="phone" required>
                    <div *ngIf="!validPhone" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Please enter a valid phone number. </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="shippingAccountNumber">Shipping Account Number<span style="color: red;">&nbsp;*</span></label>
                  <input name="shippingAccountNumber" (focus)="resetFormField('shippingAccountNumber')" type="text" [ngClass]="{'form-control': validShippingAccountNumber, 'form-control-error': !validShippingAccountNumber}" id="shippingAccountNumber">
                  <div *ngIf="!validShippingAccountNumber" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Please enter a valid shipping account number. </div>
                </div>
                <div class="mb-3">
                    <label for="address">Address<span style="color: red;">&nbsp;*</span></label>
                    <input name="address" (focus)="resetFormField('address')" type="text" [ngClass]="{'form-control': validAddress, 'form-control-error': !validAddress}" id="address" placeholder="1234 Main St" required>
                    <div *ngIf="!validAddress" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Please enter your shipping address. </div>
                </div>
                <div class="mb-3">
                  <label for="city">City<span style="color: red;">&nbsp;*</span></label>
                  <input name="city" (focus)="resetFormField('city')" type="text" [ngClass]="{'form-control': validCity, 'form-control-error': !validCity}" id="city" required>
                  <div *ngIf="!validCity" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Please provide a valid city. </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-3">
                        <label for="country">Country<span style="color: red;">&nbsp;*</span></label>
                        <select name="country" (focus)="resetFormField('country')" [ngClass]="{'custom-select d-block w-100': validCountry, 'form-control-error': !validCountry}"  id="country" required>
                            <option>US</option>
                        </select>
                        <div *ngIf="!validCountry" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Please select a valid country. </div>
                    </div>
                    <div class="col-md-5 mb-3">
                        <label for="state">State<span style="color: red;">&nbsp;*</span></label>
                        <select (change)="updateSalesTax($event.target.value)" name="state" (focus)="resetFormField('state')" [ngClass]="{'custom-select d-block w-100': validState, 'form-control-error': !validState}" id="state" required>
                            <option value="">Choose...</option>
                            <option (click)="updateSalesTax(state)" *ngFor="let state of states">{{state.state}}</option>
                        </select>
                        <div *ngIf="!validState" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Please provide a valid state. </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="zip">Zip<span style="color: red;">&nbsp;*</span></label>
                        <input maxlength="5" name="zipCode" (focus)="resetFormField('zip')" type="text" [ngClass]="{'form-control': validZip, 'form-control-error': !validZip}" style="margin-top: 0px" id="zip" placeholder="" required>
                        <div *ngIf="!validZip" class="form-error"><i class="bi bi-exclamation-circle-fill"></i>&nbsp;Zip code required. </div>
                    </div>
                </div>
                <hr class="mb-4">
            </form>
            <button style="background-color:rgb(0, 0, 95);" (click)="continueToPayment()">Submit Order</button>
        </div>
        <!-- <div *ngIf="stage === 'payment'" class="col-md-8 order-md-1">
          <h4 class="mb-3">Payment</h4>
          <div id="card-container"></div>
          <button id="card-button" type="button" style="background-color:rgb(0, 0, 95);">Submit Order</button>
        </div> -->
        <div *ngIf="stage === 'submitted'">
          <br><br><h2>Order Submitted</h2>
          Thank you for your order. To complete the payment we will call you for your credit card information. With any questions or concerns please email us
          at info@asiimedical.com or call 919-285-4426.
          <div class="row">
            <div class="col-md-6 mb-3">
              <br><br><h2>Contact Information</h2>
              <span style="font-weight: 500">Name: </span> {{orderInfo.firstname}} {{orderInfo.lastname}}
              <br><span style="font-weight: 500">Email: </span>{{orderInfo.buyerEmailAddress}}
              <br><span style="font-weight: 500">Phone: </span>{{orderInfo.phone}}
            </div>
            <div class="col-md-6 mb-3">
              <br><br><h2>Shipping Information</h2>
              <span *ngIf="orderInfo.companyName">{{orderInfo.companyName}}<br></span>
              {{orderInfo.shippingAddress.addressLine1}}<br>
              {{orderInfo.shippingAddress.locality}} {{orderInfo.shippingAddress.administrativeDistrictLevel1}} {{orderInfo.shippingAddress.postalCode}}<br>
              United States
              <br><span style="font-weight: 500">Shipping Account Number: </span>{{orderInfo.shippingAccountNumber}}
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <br><br><h2>Items Ordered:</h2>
              <span *ngFor="let item of orderInfo.cart">
                {{item.ProductName}} (Quantity: {{item.quantity}})
              </span>
            </div>
            <div class="col-md-6 mb-3">
              <br><br><h2>Payment Details</h2>
              <span style="font-weight: 500">Cart Total: </span>${{orderInfo.costDetails.cartTotal.toFixed(2)}}
              <br><span style="font-weight: 500">Sales Tax: </span>%{{orderInfo.costDetails.salesTax}}
              <br><span style="font-weight: 500">Service Fee: </span>${{orderInfo.costDetails.serviceFee.toFixed(2)}}
              <br><span style="font-weight: 500">Order Total: </span>${{orderInfo.costDetails.orderTotal.toFixed(2)}}
            </div>
          </div>

        </div>
    </div>
  </div>
</div>
