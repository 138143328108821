<header class="py-5" style="background-color: rgb(0, 0, 95);">
  <div class="container px-2 px-lg-5 my-5">
      <div class="text-center text-white pt-5">
          <h1 class="display-4 fw-bolder">ASii Medical</h1>
          <p class="lead fw-normal text-white-50 mb-0">Purchase Orders can be emailed to orders@asiimedical.com</p>
      </div>
  </div>
</header>
<!-- Section-->
<section class="py-1">
  <app-products></app-products>
</section>
<app-footer></app-footer>
